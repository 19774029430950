import React from "react"
import Layout from "../../components/layout"
import { Link } from "gatsby"
import SEO from "../../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import felisw4detail from "../../images/Felis-W4.webp"
import felisw4Doc from '../../docs/Felis-W4.pdf'
import dowDoc from "../../images/download-doc.png"
import styled from 'styled-components';

class Felisw4 extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isToggleOn: true };
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick() {
    this.setState(state => ({
      isToggleOn: !state.isToggleOn
    }));
  }

  render() {
    const ProductSpecsCol = styled(Col)`
    padding:1rem;
    `
    return <Layout>
      <SEO title="Tumble Washer Controller"
      description="Proalcs's control panel for industrial washing machines
      designed for heavy industrial conditions."/>
      <Container>
        <div className='prolacs-divider-title' style={{ marginTop: '0', backgroundColor: '#FFFFFF', display: 'flex', alignItems: 'baseline' }}>
          <br></br>
          <br></br>
          <Link className='link-breadcrumb' to='/products'><h2 style={{ fontSize: '19px' }}>Products /</h2> </Link>
          <h1 style={{ fontSize: '20px', paddingLeft: '5px' }}> Felis-W4 </h1>
        </div>
        <Row>
          <Col sm className='product-detail-head'>
            <div className={`product-detail-header-image-w4´  ${this.state.isToggleOn ? 'product-detail-header-image-w4' : 'product-detail-header-image-zoom'}`} >
              <img className='product-detail' onClick={this.handleClick} src={felisw4detail} alt="felis-w4-prolacs" />
            </div>
          </Col>
          <Col sm className='product-detail-title'>
            <div >
              <h1 className='controllerheadtext' style={{width:"100%"}}>Felis-W4</h1>
            </div>
            <h2> Tumble Washer Controller</h2>
            <p>It is a control panel for industrial washing machines
designed for heavy industrial conditions. Resistant
to vibration and dust. It has 100 programming
capacity and 9 working stages. The design of the
controller is customized according to the needs and
designed according to the customer's demands.</p>
          </Col>

        </Row>
        <Row>

          <Col sm className='product-detail-title'>
            <div >
              <h1 className='product-detail-features-header'>Documents</h1>
            </div>
            <br></br>
            <Row>
              <Col className='product-detail-doc-name'>
                <a target="" href={felisw4Doc}>  <img className='product-detail-doc' src={dowDoc} alt="proalcs felix w4 document" />
                  <h1>Felis-W4</h1>  </a>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className='prolacs-divider-title'>
          <br></br>
          <br></br>
          <h1>Specifications</h1>
        </div>
        <Row className='lms-inside-content'>
          <ProductSpecsCol >
            <li> Professional serial controller with   7 ”LCD (800x480 RGB) display.<br></br></li>
            <li>  User-friendly operation with   Color 7 ”display and keypad.<br></br></li>
            <li>  Fast commissioning with direct connection.<br></br></li>
            <li>  On board level sensor allows water level control (No need external sensor)<br></br></li>
            <li>  User programs support, troubleshooting with pictures and descriptions, diagnostic control.<br></br></li>
             and flexible use thanks to the extensive parameter list.<br></br>
            <li>  Backup and restore via USB (programs, parameters, database, version update, etc.)<br></br></li>
            <li>  Quick access to frequently used programs with 5 memory keys.<br></br></li>
            <li>  Suitable for heating / steam heating.<br></br></li>
            <li>  Suitable for Coin Operated.<br></br></li>
            <li>   Support for Tilting, Helms protocol, Wet cleaning, Washing hold support.<br></br></li>
            <li>  Sequental program execution.<br></br></li>
            <li>  AC-Drive, balance detection and laundry weight control via Modbus RTU.<br></br></li>
            <li>  Ethernet connection with OPC-UA and JENSEN cocpit support, remote control.<br></br></li>
            <li>  Ability to communicate with many data collection services with OPC-UA.<br></br></li>
          </ProductSpecsCol>
        </Row>
        <div className='prolacs-divider-title'>
          <br></br>
          <br></br>
          <h1>Features</h1>
        </div>
        <div >
          <h1 className='controllerheadtext' style={{width:"100%"}}>Inputs / Outputs</h1>
        </div>

        <Row className="product-table-row">
          <Col>
            <p>Analog Input</p>
          </Col>
          <Col>
            <p>
              Onboard Level Sensor (Hydrostatic 0-100 cm)
          <br />
              Temperature Sensor Input (-10~100 °C)
        </p>
          </Col>
          <Col className="lastp">
            <p>
              1<br />1
        </p>
          </Col>
        </Row>

        <Row className="product-table-row">
          <Col>
            <p>Digital Input</p>
          </Col>
          <Col>
            <p>5-24V DC </p>
          </Col>
          <Col className="lastp">
            <p>16</p>
          </Col>
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Counter Input</p>
          </Col>
          <Col>
            <p>5-24V DC 1 KHz</p>
          </Col>
          <Col className="lastp">
            <p>1</p>
          </Col>
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Digital Output</p>
          </Col>
          <Col>
            <p> 24V DC 1A Relay NO </p>
          </Col>
          <Col className="lastp">
            <p>24</p>
          </Col>
        </Row>

        <div>
          <h1 className='controllerheadtext' style={{width:"100%"}}>Programming</h1>
        </div>
        <Row className="product-table-row">
          <Col>
            <p>Program Count</p>
          </Col>
          <Col>
            <p>
              16 Default Programs <br />
              84 User Programs
        </p>
          </Col>
          <Col className="lastp">
            <p>100</p>
          </Col>
        </Row>
        <div  >
          <h1 className='controllerheadtext' style={{width:"100%"}}>Technical Specifications</h1>
        </div>
        <Row className="product-table-row">
          <Col>
            <p>Operating Voltage</p>
          </Col>
          <Col>
            <p>24 VDC </p>
          </Col>
          <Col className="lastp" />
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Power Consumption</p>
          </Col>
          <Col>
            <p>15 Watt</p>
          </Col>
          <Col className="lastp" />
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Operating Temperature</p>
          </Col>
          <Col>
            <p>0~60 °C</p>
          </Col>
          <Col className="lastp" />
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Operating Humidty</p>
          </Col>
          <Col>
            <p>%20~%95 (Non condensing)</p>
          </Col>
          <Col className="lastp" />
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Processor</p>
          </Col>
          <Col>
            <p>Linux Embeded CPU </p>
          </Col>
          <Col className="lastp" />
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Screen Size </p>
          </Col>
          <Col>
            <p>7” TFT</p>
          </Col>
          <Col className="lastp" />
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Screen Resolution </p>
          </Col>
          <Col>
            <p>800x480</p>
          </Col>
          <Col className="lastp" />
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Ethernet </p>
          </Col>
          <Col>
            <p>10/100 Mbit </p>
          </Col>
          <Col className="lastp" />
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>USB 2.0</p>
          </Col>
          <Col>
            <p>1</p>
          </Col>
          <Col className="lastp" />
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>RS485 Communication port</p>
          </Col>
          <Col>
            <p>1</p>
          </Col>
          <Col className="lastp" />
        </Row>


      </Container >
    </Layout >
  }
};
export default Felisw4
